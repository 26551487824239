import {
  IonContent,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import AtSearchBar from "../common/AtSearchBar";
import ModalItem from "./ModalItem";

const imagePath = "./assets/images/";
const allProducts = [
  {
    category: "vegetable-seeds",
    image: "Advanta-AK-47-Golden-Chilli-Seeds-sw_540x540.webp",
  },
  {
    category: "vegetable-seeds",
    image: "Advanta-Golden-Navya-F1-Hybrid-Bhindi-Seeds-100g-sw_720x720.jpg",
  },
  {
    category: "vegetable-seeds",
    image: "East-West-Palee-Bitter-Gourd-_-_-Seeds-sw_720x720.jpg",
  },
  {
    category: "vegetable-seeds",
    image: "Nunhems-Anokhi-Bottle-Gourd-Seeds-sw_720x720.jpg",
  },
  {
    category: "vegetable-seeds",
    image: "Radhika-F1-Hybrid-Bhindi-Seeds-sw_540x540.webp",
  },
  {
    category: "vegetable-seeds",
    image: "Seminis-Bottle-Gourd-Sharada-F1-Hybrid-Seed-sw_720x720.webp",
  },
  {
    category: "vegetable-seeds",
    image: "Seminis-Hybrid-Sponge-Gourd-Seeds-SVKP5112-sw_small.avif",
  },
  {
    category: "vegetable-seeds",
    image: "Sungro-F1-Hybrid-Brinjal-Navkiran-Seed-sw_720x720.jpg",
  },
  {
    category: "vegetable-seeds",
    image: "Sungro-F1-Hybrid-Brinjal-No704-Seed-sw_720x720.jpg",
  },
  {
    category: "vegetable-seeds",
    image: "US-Agriseeds-SW-811-Bitter-Gourd-Seeds-sw_720x720.jpg",
  },
  {
    category: "vegetable-seeds",
    image: "VNR-14-Hybrid-Pumpkin-Seeds-sw_720x720.jpg",
  },
  {
    category: "vegetable-seeds",
    image: "VNR-305-F1-Hybrid-Chilli-_-Seeds-sw_720x720.jpg",
  },
  {
    category: "vegetable-seeds",
    image: "VNR-999-Hybrid-Bhindi-Seeds-sw_720x720.webp",
  },
  {
    category: "vegetable-seeds",
    image: "VNR-B-5-Brinjal-Seeds-sw_720x720.jpg",
  },
  {
    category: "vegetable-seeds",
    image: "VNR-Deepika-Okra-_Bhindi_-Seeds-sw_720x720.jpg",
  },
  {
    category: "vegetable-seeds",
    image: "VNR-Unnati-_60-13_-Hybrid-Chilli-Seeds-10gm-sw_720x720.jpg",
  },

  {
    category: "insecticides",
    image: "Bayer-Alanto-Insecticide-sw_540x540.jpg",
  },
  { category: "insecticides", image: "Bayer-Jump-Insecticide-sw_540x540.webp" },
  {
    category: "insecticides",
    image: "Bayer-Velum-Prime-Nematicide-sw_540x540.webp",
  },
  {
    category: "insecticides",
    image: "IIL-Monocil-Insecticide-sw_540x540.webp",
  },
  { category: "insecticides", image: "PI-Osheen-Insecticide-sw_540x540.webp" },
  {
    category: "insecticides",
    image: "PIBrofreyaBroflanilde20_SCInsecticide_540x540.webp",
  },
  {
    category: "insecticides",
    image: "RimOn-Indofil-Novaluron-Insecticide-sw_720x720.jpg",
  },
  {
    category: "insecticides",
    image:
      "Sumiprempt-Insecticide-_Pyriproxyfen5_Fenpropathrin15_EC-sw_540x540.webp",
  },
  {
    category: "insecticides",
    image: "Syngenta-Actara-Insecticide_540x540.webp",
  },
  {
    category: "insecticides",
    image: "Syngenta-Pegasus-Insecticide-sw_360x360.webp",
  },
  {
    category: "insecticides",
    image: "UPL-Phoskill-Insecticide_1-sw_540x540.webp",
  },
  { category: "insecticides", image: "UPL-Ustaad-Insecticide-sw_540x540.webp" },

  {
    category: "herbicides",
    image: "Adama-2_4-D-Main-Amine-Salt-58_-SL-Herbicide-sw_540x540.webp",
  },
  { category: "herbicides", image: "Adama-Paranex-Herbicide-sw_540x540.webp" },
  {
    category: "herbicides",
    image: "Anu-71-Non-Selective-Herbicide-sw_540x540.webp",
  },
  {
    category: "herbicides",
    image: "ANU-Anustorm-_Pendimethalin30_EC_-Herbicide-sw_540x540.webp",
  },
  {
    category: "herbicides",
    image: "ANU-Anuweed-38-_2_4-D-Ethyl-Ester38_EC_-Herbicide-sw_540x540.webp",
  },
  {
    category: "herbicides",
    image: "ANU-Quizo-_Quizolop-Ethyl5_EC_-Herbicide-sw_540x540.webp",
  },
  { category: "herbicides", image: "Bayer-Sencor-Herbicide-sw_540x540.webp" },
  {
    category: "herbicides",
    image: "Bayer-Whip-Super-Herbicide-sw_540x540.webp",
  },
  {
    category: "herbicides",
    image: "HPM-Cheetah-_Imazethapyr-10_-SL_-Herbicide-sw_540x540.webp",
  },
  {
    category: "herbicides",
    image: "Indofil-Society-Herbicide-sw_540x540.webp",
  },
  { category: "herbicides", image: "UPL-Saathi-Herbicide-sw_540x540.webp" },
  { category: "herbicides", image: "UPL-Total-herbicide-sw_540x540.webp" },

  { category: "fungicides", image: "Bayer-Antracol-Fungicide-sw_540x540.webp" },
  {
    category: "fungicides",
    image: "Bayer-Evergol-Seed-Treatment-Fungicide-sw_540x540.webp",
  },
  { category: "fungicides", image: "Bayer-Folicur-Fungicide-sw_540x540.webp" },
  { category: "fungicides", image: "Bayer-Nativo-Fungicide-sw_540x540.jpg" },
  { category: "fungicides", image: "Bvistin-Fungicide-sw_540x540.webp" },
  {
    category: "fungicides",
    image: "PI-SectinFenamidone10_Mancozeb50_WDGFungicide-sw_540x540.webp",
  },
  {
    category: "fungicides",
    image:
      "Sygenta-Glo-iT-_Propiconazole-139_Difenoconazole-139_-EC_-Fungicide-sw_540x540.webp",
  },
  {
    category: "fungicides",
    image: "Syngenta-Amistar-Systemic-Fungicide-sw_540x540.webp",
  },
  {
    category: "fungicides",
    image: "Syngenta-Amistar-Top-Fungicide-sw_540x540.webp",
  },
  {
    category: "fungicides",
    image:
      "Syngenta-Ridomil-Gold-_Metalaxyl8_WP_Mancozeb64_WP_-Fungicide-sw_540x540.webp",
  },
  { category: "fungicides", image: "UPL-Cuprofix-Fungicide-sw_540x540.webp" },
  { category: "fungicides", image: "UPL-Saaf-Fungicide-sw_540x540.webp" },

  {
    category: "agricultural-tools",
    image: "Darit-Rubble-Gloves-sw_540x540.webp",
  },
  {
    category: "agricultural-tools",
    image:
      "Earth-12V-DC-Water-Pump-_Single-Motor_-For-Multiple-Uses-sw_540x540.webp",
  },
  {
    category: "agricultural-tools",
    image:
      "Green-Field-Agricultural-Sprayer-Pump-Shoulder-_Heavy_-Left-And-RightBelt-Pair-Set-sw_540x540.webp",
  },
  {
    category: "agricultural-tools",
    image:
      "Nature-Gold-Looping-hedge-shear-plant-cutting-Tools-sw_540x540.webp",
  },
  {
    category: "agricultural-tools",
    image: "Nature-Gold-Plastic-Garden-Pressure-Sprayer-sw_540x540.webp",
  },
  {
    category: "agricultural-tools",
    image: "Nature-Gold-Plastic_Copper-Sprayer-Tank-sw_540x540.webp",
  },
  {
    category: "agricultural-tools",
    image:
      "Nature-Gold-Spray-Water-Bottle-Plastic-Multicolor-Sprayers-sw_540x540.webp",
  },
  {
    category: "agricultural-tools",
    image: "Nozzle-Water-Sprayer-Gun-sw_540x540.webp",
  },
  {
    category: "agricultural-tools",
    image: "NSC-Classic-Sprayer-Water-Tank-sw_540x540.webp",
  },
  {
    category: "agricultural-tools",
    image: "Samson-12V-17A-Sprayer-Battery-Power-charger-sw_540x540.webp",
  },
  {
    category: "agricultural-tools",
    image:
      "Samson-12V-DC-Water-Pump-_DoubleMotor_-For-Multiple-Uses-sw_540x540.webp",
  },
  {
    category: "agricultural-tools",
    image: "Samson-Battery-Sprayer-Water-Tank-SAM-B-708-SHP_HP-sw_540x540.webp",
  },
  {
    category: "agricultural-tools",
    image: "Samson-SAMB-708-_SHP_-_DHP_-Double-Motor-Pump-sw_540x540.webp",
  },
];

const getPrice = (maxLimit = 50) => {
  let rand = Math.random() * maxLimit;
  console.log(rand);

  rand = Math.floor(rand); // 99

  return rand <= 0 ? 5 : rand;
};
const TabShop = () => {
  const [searchText, setSearchText] = useState<string>("");
  const [filteredProducts, setFilteredProducts] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState<any>(false);
  const [currentData, setCurrentData] = useState<any>(null);

  const getName = (item: string) => {
    const strItem = (item + "sw_").split("sw_");
    return strItem[0].replaceAll("-", " ").replaceAll("_", " ");
  };

  useEffect(() => {
    setFilteredProducts(allProducts);
  }, []);

  useEffect(() => {
    const filtered = allProducts?.filter((p: any) =>
      p?.image?.toLowerCase()?.includes(searchText.toLowerCase())
    );
    setFilteredProducts(filtered);
  }, [searchText]);

  const onClickProduct = (data: any) => {
    // alert(JSON.stringify(data))
    setCurrentData(data);
    setIsModalOpen(true);
  };
  return (
    <>
      <IonContent className="ion-padding " scrollEvents={true}>
        <IonCard color="none" at-default className="at-fixed-top">
          <AtSearchBar setSearchText={setSearchText} />
          {/* <IonCardContent>
            <IonCardTitle className="at-center">
              <small>Categories</small>
            </IonCardTitle>
          </IonCardContent> */}
        </IonCard>

        <IonCard color="none" at-default className="at-fixed-top-content">
          <IonCardContent>
            <div className="product-list pad-10-left">
              {filteredProducts &&
                filteredProducts?.map((product: any, index: number) => {
                  const image = product.image;
                  const name = getName(product.image);
                  const price = getPrice();
                  const item = {...product, price, name}
                  return (
                    <div
                      key={index}
                      className="product-category at-center cursor-pointer"
                      onClick={() => onClickProduct(item)}
                    >
                      <img
                        src={`${imagePath}${product.category}/${product.image}`}
                        alt=""
                        width="160"
                        height="160"
                      />
                      <div>
                        <b>
                          <span> AED</span> {price}
                        </b>
                      </div>
                      <div className="cat-text size-12 at-center">{name}</div>
                    </div>
                  );
                })}
            </div>
          </IonCardContent>
        </IonCard>
      </IonContent>
      {currentData && (
        <ModalItem
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            // setCurrentData(null);
          }}
          data={currentData}
        />
      )}
    </>
  );
};

export default TabShop;
