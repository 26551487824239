import { folder, checkmarkCircle } from "ionicons/icons";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonModal,
  IonButtons,
  IonIcon,
  IonCard,
  IonCardContent,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import {
  arrowBackOutline,
  call,
  mail,
  create,
  arrowUp,
  arrowDown,
} from "ionicons/icons";
import AtListItem from "../common/AtListItem";

const data = [
  {
    id: 1,
    photo: "mango.jpg",
    title: "Mango",
    title2: "AED 10",
    subtitle: "16 April 2023",
    subtitle2: (
      <>
        <span className="price-status price-status-down">
          <b>↓</b>AED -10
        </span>
      </>
    ),
    cssItem: "css-item-market",
    cssItemImage: "css-item-market-image",
  },
  {
    id: 2,
    photo: "onion.jpg",
    title: "Onion",
    title2: "AED 1",
    subtitle: "16 April 2023",
    subtitle2: (
      <>
        <span className="price-status price-status-up">
          <b>↑</b>AED 1
        </span>
      </>
    ),
    cssItem: "css-item-market",
    cssItemImage: "css-item-market-image",
  },
  {
    id: 3,
    photo: "potato.jpg",
    title: "Potato",
    title2: "AED 0.50",
    subtitle: "16 April 2023",
    subtitle2: (
      <>
        <span className="price-status price-status-down">
          <b>↓</b>AED -0.50
        </span>
      </>
    ),
    cssItem: "css-item-market",
    cssItemImage: "css-item-market-image",
  },
  {
    id: 4,
    photo: "ginger.jpg",
    title: "Ginger(Green)",
    title2: "AED 3",
    subtitle: "16 April 2023",
    subtitle2: (
      <>
        <span className="price-status price-status-up">
          <b>↑</b>AED 3.5
        </span>
      </>
    ),
    cssItem: "css-item-market",
    cssItemImage: "css-item-market-image",
  },
  {
    id: 5,
    photo: "chili.jpg",
    title: "Green Chili",
    title2: "AED 6",
    subtitle: "16 April 2023",
    subtitle2: (
      <>
        <span className="price-status price-status-down">
          <b>↓</b>AED -5
        </span>
      </>
    ),
    cssItem: "css-item-market",
    cssItemImage: "css-item-market-image",
  },
  {
    id: 6,
    photo: "pomegranate.jpg",
    title: "Pomegranate",
    title2: "AED 4",
    subtitle: "16 April 2023",
    subtitle2: (
      <>
        <span className="price-status price-status-down">
          <b>↓</b>AED -4
        </span>
      </>
    ),
    cssItem: "css-item-market",
    cssItemImage: "css-item-market-image",
  },

  {
    id: 7,
    photo: "apple.jpg",
    title: "Apple",
    title2: "AED 8",
    subtitle: "16 April 2023",
    subtitle2: (
      <>
        <span className="price-status price-status-up">
          <b>↑</b>AED 8.5
        </span>
      </>
    ),
    cssItem: "css-item-market",
    cssItemImage: "css-item-market-image",
  },
  {
    id: 8,
    photo: "banana.jpg",
    title: "Banana",
    title2: "AED 8",
    subtitle: "16 April 2023",
    subtitle2: (
      <>
        <span className="price-status price-status-down">
          <b>↓</b>AED -8
        </span>
      </>
    ),
    cssItem: "css-item-market",
    cssItemImage: "css-item-market-image",
  },
  {
    id: 9,
    photo: "lemon.jpg",
    title: "Lemon",
    title2: "AED 2.50",
    subtitle: "16 April 2023",
    subtitle2: (
      <>
        <span className="price-status price-status-up">
          <b>↑</b>AED 2.55
        </span>
      </>
    ),
    cssItem: "css-item-market",
    cssItemImage: "css-item-market-image",
  },
  {
    id: 10,
    photo: "tomato.jpg",
    title: "Tomato",
    title2: "AED 3.50",
    subtitle: "16 April 2023",
    subtitle2: (
      <>
        <span className="price-status price-status-up">
          <b>↑</b>AED 4
        </span>
      </>
    ),
    cssItem: "css-item-market",
    cssItemImage: "css-item-market-image",
  },
];

const imagePath = "./assets/images/";
// const ModalMarketPrice: React.FC<any> = ({ isOpen, onClose, data }) => {
const ModalMarketPrice: React.FC<any> = ({ isOpen, onClose }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentModal, setCurrentModal] = useState<any>("");
  const [currentData, setCurrentData] = useState<any>();
  const showModal = (modalName: any) => {
    // setCurrentModal(MODALS[modalName].toString().toLowerCase());
    setIsModalOpen(true);
  };
  const showDetails = (id: number) => {
    // showModal(MODALS.TASK_STATUS);
    // // alert("showDetails id: " + id );
    setCurrentData(data.find((dat: any) => dat.id === id));
  };
  useEffect(() => {
    // alert(JSON.stringify(data));
  }, [data]);

  const onDismiss = () => {
    // setResult({ lat: center[0], lon: center[1], name: placeName });
    onClose(1);
  };
  const saveInfo = () => {
    //   alert("Info updated \n"+
    //   JSON.stringify(state) );
    onClose(1);
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onDismiss} at-default>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onDismiss}>
              <IonIcon icon={arrowBackOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>Market Price</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonCard color="none">
          <IonCardContent>
            <AtListItem
              data={data}
              isMergedLine2={false}
              showDetails={showDetails}
              isChatEnable={true}
              isTextWrap={true}
            />
          </IonCardContent>
        </IonCard>
        <>{console.log(">>>>>", data)}</>
      </IonContent>
      <div className="at-menu-bottom">
        <IonButton expand="block" onClick={saveInfo}>
          Close
        </IonButton>
      </div>
    </IonModal>
  );
};

export default ModalMarketPrice;
