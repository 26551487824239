import { folder, checkmarkCircle } from "ionicons/icons";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonModal,
  IonButtons,
  IonIcon,
  IonCard,
  IonCardContent, 
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { arrowBackOutline, call, mail, create } from "ionicons/icons";

const imagePath = "./assets/images/";
const ModalCropPractices: React.FC<any> = ({ isOpen, onClose, data }) => {
 
  useEffect(() => {
    // alert(JSON.stringify(data));
  }, [data]);

  const onDismiss = () => {
    // setResult({ lat: center[0], lon: center[1], name: placeName });
    onClose(1);
  };
  const saveInfo = () => {
    //   alert("Info updated \n"+
    //   JSON.stringify(state) );
    onClose(1);
  };
  return (
    <IonModal isOpen={isOpen} onDidDismiss={onDismiss} at-default>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onDismiss}>
              <IonIcon icon={arrowBackOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>Crop Practices</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonCard color="none">
          <IonCardContent> 
          </IonCardContent>
        </IonCard>
      </IonContent>
      <div className="at-menu-bottom">
        <IonButton expand="block" onClick={saveInfo}>
          Close
        </IonButton>
      </div>
    </IonModal>
  );
};

export default ModalCropPractices;
