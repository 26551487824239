import { folder, checkmarkCircle } from "ionicons/icons";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonModal,
  IonButtons,
  IonIcon,
  IonCard,
  IonCardContent,
  IonItem,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { arrowBackOutline, call, mail, create } from "ionicons/icons";
import YouTubeEmbed from "./YouTubeEmbed";

const videos = [
  {
    video: "TpTIxv9LaFQ",
    title:
      "How to Grow Cassava to Fast Harvesting and Most Yield - Easy and Effective - Agriculture Technology",
  },
  {
    video: "JbNHG6Q7nek",
    title: "Former Zimbabwean Commercial Farmers Thriving in Zambia | VOANews",
  },
  {
    video: "CjdJtVJYvg8",
    title: "Soya Bean Farming in Zambia: What Happens Next After Harvesting?",
  },
  {
    video: "3vfzF2-PB7Q",
    title:
      "Vegetable Farming in Zambia: Sowing, Growing and First Harvest of our Cucumber Crop on Riverside 4",
  },
  {
    video: "NjyOjR3O4Yk",
    title:
      "Maize Farming in Zambia: How We Established a Maize Crop at our New Farm",
  },
  {
    video: "zZ3-OlWcGA8",
    title: "Vegetable Farming in Zambia: How We Grow Sweet Pepper",
  },
];
const imagePath = "./assets/images/";
const ModalVideos: React.FC<any> = ({ isOpen, onClose, data }) => {
  useEffect(() => {
    // alert(JSON.stringify(data));
  }, [data]);

  const onDismiss = () => {
    // setResult({ lat: center[0], lon: center[1], name: placeName });
    onClose(1);
  };
  const saveInfo = () => {
    //   alert("Info updated \n"+
    //   JSON.stringify(state) );
    onClose(1);
  };
  return (
    <IonModal isOpen={isOpen} onDidDismiss={onDismiss} at-default>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onDismiss}>
              <IonIcon icon={arrowBackOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>Videos</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonCard color="none">
          <IonCardContent>
            {videos.map((v: any, index: number) => {
              return (
                <div key={index}>
                  <YouTubeEmbed embedId={`${v.video}`} />
                  <p>{v.title}</p>
                  <br />
                  <br />
                </div>
              );
            })}
          </IonCardContent>
        </IonCard>
      </IonContent>
      <div className="at-menu-bottom">
        <IonButton expand="block" onClick={saveInfo}>
          Close
        </IonButton>
      </div>
    </IonModal>
  );
};

export default ModalVideos;
