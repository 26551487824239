import { folder, checkmarkCircle } from "ionicons/icons";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonModal,
  IonButtons,
  IonIcon,
  IonCard,
  IonCardContent, 
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { arrowBackOutline, call, mail, create } from "ionicons/icons";
import AtListItem from "../common/AtListItem";

const data = [
  {
    id: 1,
    photo: "pexels-photo-1.jpg",
    title: "AGCO and BASF partner on smart spraying capabilities",
    subremarks:"The partnership will integrate BASF's Smart Spraying Solution with Fendt Rogator sprayers.",
    cssItem:"css-item-with-image",
    cssItemImage:"css-item-image"
  },
  {
    id: 1,
    photo: "pexels-photo-2.jpg",
    title: "Corn planting is underway",
    subremarks:"A streak of warm and dry weather has created ideal conditions for some Zambia farmers to start planting their corn crop this week.",
    cssItem:"css-item-with-image",
    cssItemImage:"css-item-image"
  },
  {
    id: 1,
    photo: "pexels-photo-3.jpg",
    title: "Lack of right-to-repair may cost farmers more than $3 billion, says survey",
    subremarks:"A survey released this week shows that farmers are losing an average of $3,348 per year to repair downtime and restrictions because farm equipment makers limit their ability to fix equipment.",
    cssItem:"css-item-with-image",
    cssItemImage:"css-item-image"
  },
  {
    id: 1,
    photo: "pexels-photo-4.jpg",
    title: "Soy-based product piloted as sustainable solution for rough rural roads",
    subremarks:"In an effort to address deteriorating rural road conditions the Soy Transportation Coalition (STC) announced a partnership with Knox County, Illinois, and PoreShield, a soy-based concrete enhancer.",
    cssItem:"css-item-with-image",
    cssItemImage:"css-item-image"
  },
  {
    id: 1,
    photo: "pexels-photo-5.jpg",
    title: "Crop insurance is part of the safety net but not counter-cyclical",
    subremarks:"The federally subsidized crop insurance program is the largest part of the safety net but net indemnities are not counter-cyclical, said four agricultural economists.",
    cssItem:"css-item-with-image",
    cssItemImage:"css-item-image"
  },
  
  
];

const imagePath = "./assets/images/";
const ModalNews: React.FC<any> = ({ isOpen, onClose  }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentModal, setCurrentModal] = useState<any>("");
  const [currentData, setCurrentData] = useState<any>();
  const showModal = (modalName: any) => {
    // setCurrentModal(MODALS[modalName].toString().toLowerCase());
    setIsModalOpen(true);
  };
  const showDetails = (id: number) => {
    // showModal(MODALS.TASK_STATUS);
    // // alert("showDetails id: " + id );
    setCurrentData(data.find((dat: any) => dat.id === id));
  };
  useEffect(() => {
    // alert(JSON.stringify(data));
  }, [data]);

  const onDismiss = () => {
    // setResult({ lat: center[0], lon: center[1], name: placeName });
    onClose(1);
  };
  const saveInfo = () => {
    //   alert("Info updated \n"+
    //   JSON.stringify(state) );
    onClose(1);
  };
  return (
    <IonModal isOpen={isOpen} onDidDismiss={onDismiss} at-default>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onDismiss}>
              <IonIcon icon={arrowBackOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>News</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonCard color="none">
          <IonCardContent>
          <AtListItem
              data={data}
              isMergedLine2={false}
              showDetails={showDetails}
              isChatEnable={true}
              isTextWrap={true}
            />
          </IonCardContent>
        </IonCard>
      </IonContent>
      <div className="at-menu-bottom">
        <IonButton expand="block" onClick={saveInfo}>
          Close
        </IonButton>
      </div>
    </IonModal>
  );
};

export default ModalNews;
