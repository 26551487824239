import React, { useEffect, useState } from "react";

import MainSign from "./MainSign";
import MainMenu from "./customers/MainMenu";

const Main: React.FC<any> = () => {
  const [authenticated, setAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    //-- login test ----> check SignIn.tsx

    //-- check if authenticated
    const isAuth = window.localStorage.getItem("provider") !== null;
    setAuthenticated(isAuth);
  }, []);

  return <div>{!authenticated ? <MainSign /> : <MainMenu />}</div>;
};

export default Main;
