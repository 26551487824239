import React, { useState } from "react";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import { eye, eyeOff } from "ionicons/icons";
const SignUp: React.FC<any> = () => {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [state, setState] = useState<any>({
    email: "",
    password: "",
    message: "",
  });

  const SignUpHandler = () => {
    if (!state?.email) {
      setState({ ...state, message: "Email address is required" });
      return;
    }
    if (!state?.password) {
      setState({ ...state, message: "Password is required" });
      return;
    }

    if (state?.email) {
      localStorage.setItem("eml", state?.email);
      window.location.href = "/signup-info";
    }
  };
  return (
    <IonPage>
      <IonContent
        fullscreen={true}
        className="ion-padding "
        scrollEvents={true}
      >
        <div className="flex flex-right height-full user-sign">
          <IonCard>
            <IonCardHeader>
            <div className="flex flex-center">
                {/* <img
                  alt=""
                  src="../assets/images/ipaayos-logo.png"
                  height="64"
                /> */}
                <img
                  alt=""
                  src="../assets/images/snow-logo.jpg"
                  height="40"
                />
              </div>
              <IonCardTitle className="flex flex-center">
                <h1> Sign Up</h1>
              </IonCardTitle>
              {/* <IonCardTitle>
                See what's your neighbor's <br /> home improvement projects{" "}
                <br /> right now
              </IonCardTitle> */}
              {/* <IonCardSubtitle>See what's your neighbor's home improvement projects right now</IonCardSubtitle> */}
            </IonCardHeader>
            <IonCardContent>
              <div className="flex flex-center">
                <h2>
                  Join <b>iPAAYOS</b> today
                  Join <b>FSC</b> today
                </h2>
              </div>
              <IonItem color="none">
                <IonLabel position="floating">Email</IonLabel>
                <IonInput
                  placeholder="Enter your email address"
                  value={state.email}
                  onFocus={(e) => setState({ ...state, message: "" })}
                  onIonChange={(e) =>
                    setState({ ...state, email: e.detail.value! })
                  }
                />
              </IonItem>
              <IonItem color="none">
                <IonLabel position="floating">Password</IonLabel>
                <IonInput
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter your password"
                  value={state.password}
                  onFocus={(e) => setState({ ...state, message: "" })}
                  onIonChange={(e) =>
                    setState({ ...state, password: e.detail.value! })
                  }
                />

                <IonButton fill="clear"
                  slot="end"
                  className="margin-30-top icon-button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <IonIcon icon={showPassword ? eyeOff : eye} className="at-size-24" color="dark"/>
                </IonButton>
 
              </IonItem>

              <IonCardSubtitle className="at-center">
                {state.message}
              </IonCardSubtitle>

              <IonButton
                expand="block"
                className="margin-20-tb"
                onClick={SignUpHandler}
              >
                Continue
              </IonButton>
 
              <IonItem lines="none" color="none">
                <IonCardSubtitle className="at-center">
                  By signin up, you agree to the <a href="/signup">Terms of Service</a>{" "}
                  <br />
                  and <a href="/signup">Privacy Policy</a> including{" "}
                  <a href="/signup">Cookie use</a>
                </IonCardSubtitle>
              </IonItem>

              <div className="flex flex-center margin-20-tb">
                <div>
                  Already have an account?{" "}
                  <a href="/signin" onClick={() => history.push("/signin")}>
                    Log In
                  </a>
                </div>
              </div>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SignUp;
