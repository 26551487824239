import { folder, checkmarkCircle } from "ionicons/icons";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonModal,
  IonButtons,
  IonIcon,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonItem,
  IonLabel,
  IonCardSubtitle,
  IonInput,
  IonTextarea,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { arrowBackOutline, call, mail, create } from "ionicons/icons";

const imagePath = "./assets/images/";
const ModalItem: React.FC<any> = ({ isOpen, onClose, data }) => {
  const [quantity, setQuantity] = useState<number>(1);

  useEffect(() => {
    // alert(JSON.stringify(data));
  }, [data]);

  const onDismiss = () => {
    // setResult({ lat: center[0], lon: center[1], name: placeName });
    onClose(1);
  };
  const saveInfo = () => {
    //   alert("Info updated \n"+
    //   JSON.stringify(state) );
    onClose(1);
  };
  return (
    <IonModal isOpen={isOpen} onDidDismiss={onDismiss} at-default>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onDismiss}>
              <IonIcon icon={arrowBackOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {/* <IonCard color="none">
          <IonCardHeader>
            <IonCardTitle>Support</IonCardTitle>
            <IonCardSubtitle>
              Let us know your inquiries and feedback
            </IonCardSubtitle>
          </IonCardHeader>

          <IonCardContent>
            <IonItem color="none" lines="none">
              <IonIcon color="primary" icon={call} size="small" slot="start" />
              <IonLabel slot="start">
                Call us
                <div className="at-size-18 at-text-color">+9177 1794521</div>
              </IonLabel>
            </IonItem>
            <IonItem color="none" lines="none">
              <IonIcon color="primary" icon={mail} size="small" slot="start" />
              <IonLabel slot="start">
                Email us
                <div className="at-size-18 at-text-color">
                  support@ipaayos.com
                </div>
              </IonLabel>
            </IonItem>
          </IonCardContent>
        </IonCard> */}
        <IonCard color="none">
          <IonCardContent>
            <div className="grid grid-item-details">
              <div>
                <img
                  src={`${imagePath}${data.category}/${data.image}`}
                  alt=""
                  width="220"
                  height="220"
                />
              </div>
              <div>
                <small>
                  <IonIcon icon={folder} color="secondary" />
                  {data.category}
                </small>
                <div className="at-size-30">{data.name}</div>
                <div className="at-size-20">AED {data.price}</div>
                <div>
                  ✅ 100% Genuine Product
                  <br />
                  ✅ Free Agri Expert Advice <br />
                  ✅ Fast Delivery
                </div>
                <br />
                <div className="at-size-20">
                    Quantity: <input type="number" value={quantity} onChange={(e:any)=>setQuantity(e.target.value)}/>
                </div>
              </div>

            </div>
          </IonCardContent>
        </IonCard>
      </IonContent>
      <div className="at-menu-bottom">
        <IonButton expand="block" onClick={saveInfo}>
          Add to cart
        </IonButton>
        <IonButton
          expand="block"
          color="secondary"
          style={{ color: "red" }}
          onClick={saveInfo}
        >
          Buy it now
        </IonButton>
      </div>
      <>{console.log("data>>>>>", data)}</>
    </IonModal>
  );
};

export default ModalItem;
