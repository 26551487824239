import {
  IonList,
  IonItem,
  IonLabel,
  IonCardTitle,
  IonCardSubtitle, 
} from "@ionic/react";
import React from "react";
// import { call, chatbubbleEllipses } from "ionicons/icons";
const AtListItem: React.FC<any> = ({
  data,
  isReadOnly = false,
  isMergedLine2 = false,
  // isBorderPrimary = false,
  // isChatEnable = false,
  isTextWrap = false,
  showDetails,
}) => {
  return (
    <>
      {data &&
        data.map((dat: any, index: number) => {
          return (
            <IonList
              key={index}
              className={`${isReadOnly ? "" : "at-cursor-pointer"}`}
              onClick={() => !isReadOnly && showDetails(dat.id)}
            >
              <div className="at-item-list">
                <img
                  alt="" 
                  className={`${dat?.cssItemImage} task-image `}
                  src={`/assets/images/${dat.photo}`}
                  width="60"
                ></img>
                {/* <IonItem lines="none" className={`${dat?.cssItem} `}> */}
                <IonItem className={`${dat?.cssItem} `}>
                  <IonLabel position="stacked" slot="start"></IonLabel>
                  {dat.title && (
                    <IonCardTitle className="at-size-16">
                      <span
                        className={`column ${isTextWrap ? "at-text-wrap" : ""}`}
                      >
                        {dat.title}
                      </span>
                      <span
                        className={`${dat?.title2 ? "at-right" : ""} 
                        ${dat?.cssTitle2}`}
                      >
                        {dat.title2}
                      </span>
                    </IonCardTitle>
                  )}
                  {dat.subtitle && (
                    <IonCardSubtitle className="at-size-12 at-margin-5-top">
                      <span
                        className={`${isTextWrap ? "at-text-wrap" : ""} ${`${isMergedLine2 ? "" : "column"}`}`}
                      >
                        {dat.subtitle}
                        &nbsp; {`${isMergedLine2 ? "|" : ""}`} &nbsp;
                      </span>
                      <span
                      // className={`${isMergedLine2 ? "" : "column-2 at-right"}`}
                      >
                        {dat.subtitle2}
                      </span>
                    </IonCardSubtitle>
                  )}
                  {/* {dat.remarks && <div className="at-margin-5-top"> <p>{dat.remarks}</p></div>} */}
                  {/* {dat.remarks &&  <p>{dat.remarks}</p> } */}
                  {dat.remarks && (
                    <IonCardSubtitle color="primary">
                      <p
                        className={`column ${isTextWrap ? "at-text-wrap" : ""}`}
                      >
                        {dat.remarks}
                      </p>
                      {dat.remarks2 && (
                        <p className="at-right">{dat.remarks2}</p>
                      )}
                      {/* {isChatEnable && (
                        <>
                           <div className="column-2 at-right at-size-24 at-pad-10-left">
                            <IonIcon
                              icon={chatbubbleEllipses}
                              color="primary"
                              onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                alert("chat");
                              }}
                            ></IonIcon>
                          </div>  
                            <div className=" at-size-24 at-pad-10-left">
                            <IonIcon
                              icon={call}
                              color="primary"
                              onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                window.open("tel:+971503908526")
                            
                              }}
                            ></IonIcon>
                          </div>  
                        </>
                      )} */}
                    </IonCardSubtitle>
                  )}
                  {dat.subremarks && (
                    <IonCardSubtitle className="at-margin-10-tb ">
                      <p className={`${isTextWrap ? "at-text-wrap" : ""}`}>
                        {dat.subremarks}
                      </p>
                    </IonCardSubtitle>
                  )}
                </IonItem>
              </div>
            </IonList>
          );
        })}
    </>
  );
};

export default AtListItem;
