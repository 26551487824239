import React, { useEffect, useState } from "react";
import {
  home,
  homeOutline,
  cart,
  cartOutline,
  leaf,
  leafOutline,
  chatbox,
  chatboxOutline,
  personCircleSharp,
  personCircleOutline,
} from "ionicons/icons";
import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonHeader,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Route, Redirect } from "react-router";
// import TabPost from "./TabPost";
// import TabBrowse from "./TabBrowse";
// import TabTasks from "./TabTasks";
// import TabNotifications from "./TabNotifications";
import TabAccount from "./TabAccount";
import { titleCase } from "../../utils/helpers";
import TabMyHome from "./TabMyHome";
import TabChat from "./TabChat";
import TabCrop from "./TabCrop";
import TabShop from "./TabShop";
const MainMenu: React.FC<any> = () => {
  // const [selectedTab, setSelectedTab] = useState("post");
  const [selectedTab, setSelectedTab] = useState("");

  useEffect(() => {
    // console.log("selectedTab >>>>", selectedTab, window.location.pathname)
    const pathname = titleCase(window.location.pathname.replaceAll("/", ""));
    setSelectedTab(pathname);
  }, []);

  const onRedirect = () => {
    // setSelectedTab("Account")
    // return <Redirect to="/account" />
    setSelectedTab("Home");
    return <Redirect to="/home" />;
  };
  return (
    <>
      <IonHeader translucent className="ion-no-border">
        <IonToolbar>
          {selectedTab === "Home" ? (
            <IonTitle> Farming Crop Solutions</IonTitle>
          ) : (
            <IonTitle className="">{selectedTab}</IonTitle>
          )}
          {/* <IonTitle className="">{selectedTab}</IonTitle> 1*/}
        </IonToolbar>
      </IonHeader>

      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
            <Route exact={true} path="/home" component={() => <TabMyHome />} />
            {/* <Route exact={true} path="/home" component={() => <TabShop />} /> */}
            <Route exact={true} path="/shop" component={() => <TabShop />} />
            <Route exact={true} path="/crop" component={() => <TabCrop />} />
            <Route exact={true} path="/chat" component={() => <TabChat />} />
       


            {/* <Route exact={true} path="/post" component={() => <TabPost />} />
            <Route
              exact={true}
              path="/browse"
              component={() => <TabBrowse />}
            />
            <Route exact={true} path="/tasks" component={() => <TabTasks />} />

            <Route
              exact={true}
              path="/notifications"
              component={() => <TabNotifications />}
            /> */}
            <Route
              exact={true}
              path="/account"
              component={() => <TabAccount />}
            />

            <Route
              exact={true}
              path="/"
              render={() => <Redirect to="/home" />}
            />
            {/* <Route
              exact={true}
              path="/home"
              render={() => <Redirect to="/home" />}
            /> */}
            <Route render={onRedirect} />
          </IonRouterOutlet>

          <IonTabBar
            slot="bottom"
            // hidden={platform.name === "web"} //-- temporarily disabled
            selectedTab="account"
            onIonTabsDidChange={(e) =>
              // setSelectedTab(e.detail.tab.toLowerCase())
              setSelectedTab(e.detail.tab)
            }
            className="pad-5-tb"
          >
            
            <IonTabButton tab="Home" href="/home">
              <IonIcon
                icon={selectedTab === "Home" ? home : homeOutline} 
              />
              <IonLabel>Home</IonLabel>
            </IonTabButton>
            <IonTabButton tab="Shop" href="/shop">
              <IonIcon
                icon={selectedTab === "Shop" ? cart : cartOutline} 
              />
              <IonLabel>Shop</IonLabel>
            </IonTabButton>
            <IonTabButton tab="Crop" href="/crop">
              <IonIcon
                icon={selectedTab === "Crop" ? leaf : leafOutline} 
              />
              <IonLabel>Crop</IonLabel>
            </IonTabButton>
 
            <IonTabButton tab="Chat" href="/chat">
              <IonIcon
                icon={selectedTab === "Chat" ? chatbox : chatboxOutline} 
              />
              <IonLabel>Chat</IonLabel>
            </IonTabButton>
 
            <IonTabButton tab="Account" href="/account">
              <IonIcon
                icon={
                  selectedTab === "Account"
                    ? personCircleSharp
                    : personCircleOutline
                }
              />
              <IonLabel>Account</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </>
  );
};

export default MainMenu;
