import React, { useState } from "react";
import {
  IonLabel,
  IonContent,
  IonItem,
  IonButton,
  IonIcon,
  useIonAlert,
} from "@ionic/react";
import { 
  mail,
  documentLock,
  helpCircle,
  power,
} from "ionicons/icons";
import Modals from "./modals/Modals";
import { MODALS } from "./modals";
const TabAccount: React.FC<any> = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentModal, setCurrentModal] = useState<any>("");
  const [presentAlert] = useIonAlert();

  const showModal = (modalName: any) => {
    setCurrentModal(MODALS[modalName].toString().toLowerCase());
    setIsModalOpen(true);
  };

  const onLogout =()=>{

    window.localStorage.clear();
    window.location.href="/";
  }
  return (
    <> 
      {currentModal && (
        <Modals
          component={currentModal}
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
        />
      )}

      <IonContent className="ion-padding " scrollEvents={true}>
        <IonItem
          className="pad-20-bottom cursor-pointer"
          color="none"
          lines="none"
          detail={true}
          onClick={() => {
            showModal(MODALS.ACCOUNT);
          }}
        >
          <img
            alt=""
            className="task-image"
            // slot="start"
            src="/assets/images/auric_gavin.jpg"
            width="60"
          ></img>
          {/* <div
          style={{ backgroundImage: `url(${profilePhoto})` }}
          // style={divImage}
          className="photo-container"
        >
          {provider && provider?.photo + "" === "" && (
            <IonIcon icon={personOutline} className="size-36 profile-photo" />
          )}
        </div> */}

          <div className="pad-20-left">
            <IonLabel className="at-size-18"> Adonis Tividad</IonLabel>
            <IonLabel className="at-opacity-5">+63 977 1794 521</IonLabel>
            <br />
          </div>
        </IonItem>

  
        <IonItem lines="none" color="none">
          <IonButton
            className="at-list-button"
            onClick={() => {
              showModal(MODALS.SUPPORT);
            }}
          >
            <IonIcon
              className="pad-10"
              color="primary"
              icon={mail}
              size="small"
            />
            <IonLabel className="pad-10-left">Support</IonLabel>
          </IonButton>
        </IonItem>
        <IonItem lines="none" color="none" disabled={true}>
          <IonButton
            className="at-list-button"
            onClick={() => {
              showModal(MODALS.TERMS);
            }}
          >
            <IonIcon
              className="pad-10"
              color="primary"
              icon={documentLock}
              size="small"
            />
            <IonLabel className="pad-10-left">Terms & Conditions</IonLabel>
          </IonButton>
        </IonItem>
        <IonItem lines="none" color="none" disabled={true}>
          <IonButton
            className="at-list-button"
            onClick={() => {
              showModal(MODALS.ACCOUNT);
            }}
          >
            <IonIcon
              className="pad-10"
              color="primary"
              icon={helpCircle}
              size="small"
            />
            <IonLabel className="pad-10-left">FAQs & Terms</IonLabel>
          </IonButton>
        </IonItem>
        <IonItem lines="none" color="none">
          <IonButton className="at-list-button" 
          // onClick={onLogout}
          onClick={() =>
            presentAlert({
              header: 'LOGOUT',
              message:"Are you sure you want to logout?", 
              cssClass: 'custom-alert',
              buttons: [
                {
                  text: 'No',
                  cssClass: 'alert-button-cancel',
                },
                {
                  text: 'Yes',
                  cssClass: 'alert-button-confirm',
                  handler: () => {onLogout()},
                },
              ],
            })
          }
          >
            <IonIcon
              className="pad-10"
              color="primary"
              icon={power}
              size="small"
            />
            <IonLabel className="pad-10-left">Logout</IonLabel>
          </IonButton>
        </IonItem>
      </IonContent>
    </>
  );
};

export default TabAccount;
