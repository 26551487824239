import { folder, checkmarkCircle } from "ionicons/icons";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonModal,
  IonButtons,
  IonIcon,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonItem,
  IonLabel,
  IonCardSubtitle,
  IonInput,
  IonTextarea,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { arrowBackOutline, call, mail, create } from "ionicons/icons";

const weather = [
  {
    time: "01am - 02am",
    weather_ico: "ptcloudy.webp",
    temp: "5°C",
    wind_ico: "wd_nnw.webp",
    wind: "3mph",
    air: "1026mb",
    precip: "0mm None",
    storm_risk: "0",
  },
  {
    time: "02am - 03am",
    weather_ico: "ptcloudy_nt.webp",
    temp: "5°C",
    wind_ico: "wd_ssw.webp",
    wind: "2mph",
    air: "1026mb",
    precip: "0mm None",
    storm_risk: "0",
  },
  {
    time: "03am - 04am",
    weather_ico: "mscloudy.webp",
    temp: "5°C",
    wind_ico: "wd_nnw.webp",
    wind: "4mph",
    air: "1026mb",
    precip: "0mm None",
    storm_risk: "0",
  },
  {
    time: "04am - 05am",
    weather_ico: "ptcloudy.webp",
    temp: "8°C",
    wind_ico: "wd_ssw.webp",
    wind: "5mph",
    air: "1026mb",
    precip: "0mm None",
    storm_risk: "0",
  },
  {
    time: "05am - 06am",
    weather_ico: "mscloudy.webp",
    temp: "11°C",
    wind_ico: "wd_nnw.webp",
    wind: "1mph",
    air: "1026mb",
    precip: "0mm None",
    storm_risk: "0",
  },
  {
    time: "06am - 07am",
    weather_ico: "mscloudy.webp",
    temp: "12°C",
    wind_ico: "wd_ssw.webp",
    wind: "4mph",
    air: "1026mb",
    precip: "0mm None",
    storm_risk: "0",
  },
  {
    time: "07am - 08am",
    weather_ico: "ptcloudy_nt.webp",
    temp: "13°C",
    wind_ico: "wd_se.webp",
    wind: "5mph",
    air: "1026mb",
    precip: "0mm None",
    storm_risk: "0",
  },
  {
    time: "08am - 09am",
    weather_ico: "ptcloudy.webp",
    temp: "13°C",
    wind_ico: "wd_ssw.webp",
    wind: "6mph",
    air: "1026mb",
    precip: "0mm None",
    storm_risk: "0",
  },
  {
    time: "09am - 10am",
    weather_ico: "mscloudy.webp",
    temp: "13°C",
    wind_ico: "wd_nnw.webp",
    wind: "3-9mph",
    air: "1026mb",
    precip: "0mm None",
    storm_risk: "0",
  },
  {
    time: "10am - 11am",
    weather_ico: "ptcloudy_nt.webp",
    temp: "14°C",
    wind_ico: "wd_ssw.webp",
    wind: "3-8mph",
    air: "1026mb",
    precip: "0mm None",
    storm_risk: "0",
  },
  {
    time: "11am - 12am",
    weather_ico: "mscloudy.webp",
    temp: "14°C",
    wind_ico: "wd_sse.webp",
    wind: "3mph",
    air: "1026mb",
    precip: "0mm None",
    storm_risk: "0",
  },
  {
    time: "12am - 13am",
    weather_ico: "mscloudy.webp",
    temp: "15°C",
    wind_ico: "wd_s.webp",
    wind: "4mph",
    air: "1026mb",
    precip: "0mm None",
    storm_risk: "0",
  },
  {
    time: "01pm - 2pm",
    weather_ico: "ptcloudy_nt.webp",
    temp: "15°C",
    wind_ico: "wd_sse.webp",
    wind: "5mph",
    air: "1026mb",
    precip: "0mm None",
    storm_risk: "0",
  },
  {
    time: "02pm - 3pm",
    weather_ico: "ptcloudy_nt.webp",
    temp: "15°C",
    wind_ico: "wd_s.webp",
    wind: "5mph",
    air: "1026mb",
    precip: "0mm None",
    storm_risk: "0",
  },
];
const imagePath = "./assets/images/weather/";
const ModalWeather: React.FC<any> = ({ isOpen, onClose, data }) => {
  const tabNames = [
    "Today",
    "Tomorrow",
    "Tue 18/04",
    "Wed 19/04",
    // "Thu 20/04",
    // "Fri 21/04",
    // "Sat 21/04",
  ];
  const [tabName, setTabName] = useState<any>("Today");
  const [mySwiper, setMySwiper] = useState<any>();

  const onDismiss = () => {
    // setResult({ lat: center[0], lon: center[1], name: placeName });
    onClose(1);
  };
  const saveInfo = () => {
    //   alert("Info updated \n"+
    //   JSON.stringify(state) );
    onClose(1);
  };
  const onChangeTabName = (type: any) => {
    const slideNo = tabNames.findIndex(
      (tab: any) => tab.toLowerCase() === type
    );
    mySwiper.slideTo(slideNo);
  };
  return (
    <IonModal isOpen={isOpen} onDidDismiss={onDismiss} at-default>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onDismiss}>
              <IonIcon icon={arrowBackOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>Weather</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonSegment
          value={tabName}
          onIonChange={(e) => onChangeTabName(e.detail.value)}
        >
          {tabNames.map((t: any, index: number) => {
            return (
              <IonSegmentButton value={t} key={index}>
                <IonLabel
                  style={{ textTransform: "none" }}
                  className="at-bold at-size-10"
                >
                  {t}
                </IonLabel>
              </IonSegmentButton>
            );
          })}
        </IonSegment>

        <IonCard color="none">
          <IonCardContent>
            <table style={{ fontSize: "14px" }}>
              <thead>
                <tr className="at-center" style={{ color: "#444" }}>
                  <th className="at-center">Time</th>
                  <th className="at-center">Weather</th>
                  <th className="at-center">Temp</th>
                  <th className="at-center">Wind</th>
                  <th className="at-center">Air Pressure</th>
                  <th className="at-center">Precip</th>
                  <th className="at-center">Storm Risk</th>
                </tr>
              </thead>
              <tbody>
                {weather.map((w: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td className="at-center">{w.time}</td>
                      <td className="at-center">
                        <img src={`${imagePath}${w.weather_ico}`} alt="" />
                      </td>
                      <td className="at-center">{w.temp}</td>
                      <td className="at-center">
                        <img src={`${imagePath}${w.wind_ico}`} alt="" />
                        <br />
                        {w.wind}
                      </td>
                      <td className="at-center">{w.air}</td>
                      <td className="at-center">{w.precip}</td>
                      <td className="at-center">{w.storm_risk}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </IonCardContent>
        </IonCard>
      </IonContent>
      <div className="at-menu-bottom">
        <IonButton expand="block" onClick={saveInfo}>
          Close
        </IonButton>
      </div>
    </IonModal>
  );
};

export default ModalWeather;
