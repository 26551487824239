import {
  IonContent,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
} from "@ionic/react";
import React from "react";

const TabCrop = () => {
  return (
    <>
      <IonContent className="ion-padding " scrollEvents={true}>
        <IonCard color="none" at-default>
          <IonCardContent>
            <IonCardTitle className="at-center">
              <small>Crop Page</small>
            </IonCardTitle>
 
          </IonCardContent>
        </IonCard>

        <IonCard color="none" at-default>
          <IonCardContent></IonCardContent>
        </IonCard>
      </IonContent>
    </>
  );
};

export default TabCrop;
