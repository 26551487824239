import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { eye, eyeOff } from "ionicons/icons";
import qs from "qs";

const SignOTP: React.FC<any> = () => {
  const [showOTP, setShowOTP] = useState<boolean>(false);
  // const [searchParams, setSearchParams] = useSearchParams();
  // searchParams.get("__firebase_request_key")
  const [state, setState] = useState<any>({
    email: "",
    OTP: "",
    message: "",
    mode: "",
  });

  useEffect(() => {
    const email = localStorage.getItem("eml");
    const queryString = window.location.search.replace(/^\?/, "");
    const queryParams = qs.parse(queryString);
    if (email) {
      setState({ ...state, email, mode: queryParams?.mode });
    } else {
      window.location.href = "/signup";
    }
 
    // eslint-disable-next-line
  }, []);

  const SignUpHandler = () => {
    if (!state?.OTP) {
      setState({ ...state, message: "OTP is required" });
      return;
    }
    if (state?.OTP !== "1234") {
      setState({ ...state, message: "Invalid OTP" });
      return;
    }
    // alert(state.email)
    // if (state?.email || state?.mode) { 
      localStorage.removeItem("eml");
      window.location.href = state.mode ? `/${state?.mode}` : "/home";
    // }
  };
  return (
    <>
      <IonContent
        fullscreen={true}
        className="ion-padding "
        scrollEvents={true}
      >
        <div className="flex flex-right height-full user-sign">
          <IonCard>
            <IonCardHeader>
              <div className="flex flex-center">
                <img
                  alt=""
                  src="../assets/images/snow-logo.jpg"
                  width="64"
                />
              </div>
              <IonCardTitle className="flex flex-center">
                <h1> Verification</h1>
              </IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
              <div className="flex flex-center">
                <h2>
                  Enter verification code sent to: <br /> <b> {state.email}</b>
                </h2>
              </div>
              <IonItem color="none">
                <IonLabel position="floating">Enter Code</IonLabel>
                <IonInput
                  placeholder="Enter code"
                  type={showOTP ? "text" : "password"}
                  value={state.OTP}
                  onFocus={(e) => setState({ ...state, message: "" })}
                  onIonChange={(e) =>
                    setState({ ...state, OTP: e.detail.value! })
                  }
                />
                <IonButton
                  fill="clear"
                  slot="end"
                  className="margin-30-top icon-button"
                  onClick={() => setShowOTP(!showOTP)}
                >
                  <IonIcon
                    icon={showOTP ? eyeOff : eye}
                    className="at-size-24"
                    color="dark"
                  />
                </IonButton>
              </IonItem>
              <IonCardSubtitle className="at-center">
                {state.message}
              </IonCardSubtitle>

              <br />
              <br />
              <br />
              <IonButton
                expand="block"
                className="margin-20-tb"
                onClick={SignUpHandler}
              >
                Confirm
              </IonButton>
            </IonCardContent>
          </IonCard>
        </div> 
      </IonContent>
    </>
  );
};

export default SignOTP;
