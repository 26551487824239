import {
  IonContent,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import AvtChat from "../common/AvtChat";

const TabChat = () => {
  const [isOpenChat, setIsOpenChat] = useState<boolean>(false);

  useEffect(() => {
    setIsOpenChat(true);
  }, []);

  return (
    <>
      <IonContent className="ion-padding " scrollEvents={true}>
        <IonCard color="none" at-default>
          <IonCardContent>
            <IonCardTitle className="at-center">
              <button onClick={() => setIsOpenChat(true)}>
                Open Chat Page
              </button>
            </IonCardTitle>
          </IonCardContent>
        </IonCard>

        <IonCard color="none" at-default>
          <IonCardContent></IonCardContent>
        </IonCard>
      </IonContent>
      <AvtChat isOpen={isOpenChat} onClose={() => setIsOpenChat(false)} />
    </>
  );
};

export default TabChat;
