import {
  IonContent,
  IonCard,
  IonCardContent,
  IonTitle,
  IonCardSubtitle,
  IonCardTitle,
} from "@ionic/react";
import React, { useState } from "react";
import AtListItem from "../common/AtListItem";
import { useHistory } from "react-router";
import ModalWeather from "./ModalWeather";
import ModalDemo from "./ModalDemo";
import ModalNews from "./ModalNews";
import ModalCropPractices from "./ModalCropPractices";
import ModalCropCare from "./ModalCropCare";
import ModalCommunity from "./ModalCommunity";
import ModalVideos from "./ModalVideos";
import ModalMarketPrice from "./ModalMarketPrice";
const allCategories = [
  {
    id: 1,
    name: "Shop",
    icon: "./assets/images/shopping-cart.png",
    class_name: null,
    width: 48,
    status: "active",
    ts: "2022-04-03T10:56:46.000Z",
  },
  {
    id: 2,
    name: "Crop Practices",
    icon: "./assets/images/open-book.png",
    class_name: null,
    width: 48,
    status: "active",
    ts: "2022-04-03T10:56:46.000Z",
  },
  {
    id: 3,
    name: "Crop Advisory",
    icon: "./assets/images/customer-service-agent.png",
    class_name: null,
    width: 48,
    status: "active",
    ts: "2022-04-03T10:58:41.000Z",
  },
  {
    id: 4,
    name: "Crop Care",
    icon: "./assets/images/leaf.png",
    class_name: null,
    width: 48,
    status: "active",
    ts: "2022-04-03T10:58:41.000Z",
  },

  {
    id: 5,
    name: "Community",
    icon: "./assets/images/conversation.png",
    class_name: null,
    width: 48,
    status: "active",
    ts: "2022-04-03T10:58:41.000Z",
  },
  {
    id: 6,
    name: "Videos",
    icon: "./assets/images/video.png",
    class_name: null,
    width: 48,
    status: "active",
    ts: "2022-04-03T10:58:41.000Z",
  },
  {
    id: 7,
    name: "News",
    icon: "./assets/images/newspaper.png",
    class_name: null,
    width: 48,
    status: "active",
    ts: "2022-04-03T10:58:41.000Z",
  },
  {
    id: 8,
    name: "Weather",
    icon: "./assets/images/rain.png",
    class_name: null,
    width: 48,
    status: "active",
    ts: "2022-04-03T10:58:41.000Z",
  },
  {
    id: 9,
    name: "Market Price",
    icon: "./assets/images/online-shop.png",
    class_name: null,
    width: 48,
    status: "active",
    ts: "2022-04-03T10:58:41.000Z",
  },
  // {
  //   id: 10,
  //   name: "Soil Testing",
  //   icon: "./assets/images/home.png",
  //   class_name: null,
  //   width: 48,
  //   status: "active",
  //   ts: "2022-04-03T10:58:41.000Z",
  // },
];

const TabPost: React.FC<any> = () => {
  const history = useHistory();
  const [state, setState] = useState<any>({
    category: "",
    showModal: false,
  });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [data, setData] = useState<any>({
    // user_id: auth?.id,
    category: "Shop",
    status: "posted",
    remarks: "",
    // "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quidem aliquid veniam dignissimos nulla. Quo, quisquam inventore. At earum debitis iusto, laudantium adipisci eius optio dolores nobis, numquam quae sint ducimus?",
  });
  const [isModalOpen_Weather, setIsModalOpen_Weather] =
    useState<boolean>(false);
  const [isModalOpen_News, setIsModalOpen_News] = useState<boolean>(false);
  const [isModalOpen_Practices, setIsModalOpen_Practices] =
    useState<boolean>(false);
  const [isModalOpen_CropCare, setIsModalOpen_CropCare] =
    useState<boolean>(false);
  const [isModalOpen_Community, setIsModalOpen_Community] =
    useState<boolean>(false);
  const [isModalOpen_Videos, setIsModalOpen_Videos] = useState<boolean>(false);
  const [isModalOpen_Market, setIsModalOpen_Market] = useState<boolean>(false);

  const onClickCategory = (cat: string) => {
    // alert("onClickCategory");
    // history.push(`/post/${cat.toLowerCase().replaceAll(" ", "_")}`);
    setData({ ...data, category: cat });

    // alert(cat);
    switch (cat.toLowerCase()) {
      case "shop":
        history.push("/shop");
        break;

      case "crop practices":
        setIsModalOpen_Practices(true);
        break;

      case "crop advisory":
        history.push("/chat");
        break;

      case "crop care":
        setIsModalOpen_CropCare(true);
        break;

      case "community":
        setIsModalOpen_Community(true);
        break;

      case "videos":
        setIsModalOpen_Videos(true);
        break;

      case "news":
        setIsModalOpen_News(true);
        break;

      case "weather":
        setIsModalOpen_Weather(true);
        break;

      case "market price":
        setIsModalOpen_Market(true);
        break;
    }
    setIsModalOpen(true);
  };

  return (
    <>
      <IonContent className="ion-padding " scrollEvents={true}>
        <IonCard color="none" at-default>
          <IonCardContent>
            {/* <IonCardTitle className="at-center">
              <small>
                Good Morning, Auric Gavin! 
              </small>
            </IonCardTitle>
            <IonCardSubtitle className="at-center">
              What do you need help with?
            </IonCardSubtitle>
             */}
          </IonCardContent>
        </IonCard>

        <IonCard color="none" at-default>
          <IonCardContent style={{ marginRight: "24px" }}>
            <div className="main-categories pad-10-left">
              {allCategories &&
                allCategories?.map((cat: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className="category at-center cursor-pointer"
                      onClick={() => onClickCategory(cat.name)}
                    >
                      <div className="cat-icon">
                        <img
                          src={cat.icon}
                          alt="icon"
                          width={cat.width}
                          // className={cat.className}
                          className="pad-15"
                        />
                      </div>
                      <div className="cat-text size-12 at-center">
                        {cat.name}
                      </div>
                    </div>
                  );
                })}
            </div>
          </IonCardContent>
        </IonCard>
      </IonContent>
      <ModalWeather
        isOpen={isModalOpen_Weather}
        onClose={() => {
          setIsModalOpen_Weather(false);
          // setCurrentData(null);
        }}
        // data={currentData}
      />
      <ModalNews
        isOpen={isModalOpen_News}
        onClose={() => {
          setIsModalOpen_News(false);
          // setCurrentData(null);
        }}
      />
      <ModalCropPractices
        isOpen={isModalOpen_Practices}
        onClose={() => {
          setIsModalOpen_Practices(false);
          // setCurrentData(null);
        }}
      />
      <ModalCropCare
        isOpen={isModalOpen_CropCare}
        onClose={() => {
          setIsModalOpen_CropCare(false);
          // setCurrentData(null);
        }}
      />
      <ModalCommunity
        isOpen={isModalOpen_Community}
        onClose={() => {
          setIsModalOpen_Community(false);
          // setCurrentData(null);
        }}
      />
      <ModalVideos
        isOpen={isModalOpen_Videos}
        onClose={() => {
          setIsModalOpen_Videos(false);
          // setCurrentData(null);
        }}
      />
      <ModalMarketPrice
        isOpen={isModalOpen_Market}
        onClose={() => {
          setIsModalOpen_Market(false);
          // setCurrentData(null);
        }}
      />
    </>
  );
};

export default TabPost;
